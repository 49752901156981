import { NavButtonProps } from "./NavButton.props";
import styles from "./NavButton.module.scss";
import { cn } from "../../helpers/classNames";
import { PlayIcon } from "..";
import { NavLink } from "react-router-dom";
import { clearIndexedDB } from "../../helpers/clearIndexedDB";

export const NavButton = ({
  gameId,
  level,
  nameLevel,
}: NavButtonProps): JSX.Element => {
  return (
    <NavLink
      to={`/${gameId}`}
      className={({ isActive }) =>
        `${isActive ? `${styles.active} ${styles.navLink}` : styles.navLink}`
      }
      onClick={() => {
        setTimeout(function () {
          window.location.reload();
        });
      }}
    >
      {({ isActive }) => (
        <>
          <PlayIcon
            place="nav"
            className={styles.playIcon}
            isActive={isActive}
          />
          <div className={styles.wrapper}>
            <span
              className={cn(styles.level, {
                [styles.active]: isActive,
              })}
            >{`Уровень ${level}`}</span>
            <h3
              className={cn(styles.nameLevel, {
                [styles.active]: isActive,
              })}
            >
              {nameLevel}
            </h3>
          </div>
        </>
      )}
    </NavLink>
  );
};
