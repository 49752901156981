import styles from "./AndroidWarningBanner.module.scss";

import { ReactComponent as WarningIcon } from "../../shared/assets/images/danger.svg";

export const AndroidWarningBanner = () => {
  return (
    <div className={styles.container}>
      <WarningIcon />
      <p className={styles.text}>
        Наблюдаются проблемы с загрузкой игры в Вашем браузере. Вы можете
        бесплатно скачать игру – по кнопке ниже
      </p>
    </div>
  );
};
