import { Modal } from "../../shared/ui/Modal";

import styles from "./AndroidWarningModal.module.scss";

import { ReactComponent as CloseIcon } from "../../shared/assets/images/close-square.svg";
import { ReactComponent as WarningIcon } from "../../shared/assets/images/danger.svg";
import { AppStoreLink } from "../../shared/ui";
import { AndroidWarningModalPropsI } from "./AndroidWarningModal.props";

export const AndroidWarningModal = ({
  isVisible,
  setIsVisible,
}: AndroidWarningModalPropsI) => {
  const handleCloseModal = () => {
    setIsVisible(false);
  };
  return (
    <Modal
      additionalClasses={styles.modal}
      isVisible={isVisible}
      close={handleCloseModal}
    >
      <div className={styles.header}>
        <WarningIcon />
        <button onClick={handleCloseModal} className={styles.warning_button}>
          <CloseIcon className={styles.warrning_icon} />
        </button>
      </div>
      <p className={styles.text}>
        Наблюдаются проблемы с загрузкой игры в Вашем браузере. Вы можете
        бесплатно скачать игру – по кнопке ниже
      </p>
      <ul className={styles.list}>
        <li>
          <AppStoreLink
            href="https://www.rustore.ru/catalog/app/ru.radiovera.vifii"
            store="ruStore"
          />
        </li>
        <li>
          <AppStoreLink
            href="https://apps.apple.com/us/app/вера-и-фома-игра-в-историю/id6503144010"
            store="appStore"
          />
        </li>
        <li>
          <AppStoreLink
            href="https://play.google.com/store/apps/details?id=ru.radiovera.vifii"
            store="googlePlay"
          />
        </li>
      </ul>
    </Modal>
  );
};
