import { NavigationProps } from './Navigation.props'
import styles from './Navigation.module.scss'
import { NavButton } from '../../shared/ui'
import { gamesData } from '../../shared/constans/games_data'

export const Navigation = ({
  className,
  ...props
}: NavigationProps): JSX.Element => {
  return (
    <nav className={styles.nav} {...props}>
      <ul className={styles.list}>
        {gamesData.map(navLink => (
          <li className={styles.item} key={navLink.id}>
            <NavButton
              nameLevel={navLink.nameLevel}
              level={navLink.level}
              gameId={navLink.id}
            />
          </li>
        ))}
      </ul>
    </nav>
  )
}
