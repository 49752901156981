import { CloseButtonProps } from './CloseButton.props'
import styles from './CloseButton.module.scss'
import { cn } from '../../helpers/classNames'

export const CloseButton = ({
  color,
  onClose,
  className = '',
  ...props
}: CloseButtonProps): JSX.Element => {
  return (
    <button
      className={cn(styles.closeButton, {}, [className])}
      type="button"
      onClick={onClose}
      {...props}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2266 19.7733L19.7732 12.2267"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.7732 19.7733L12.2266 12.2267"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0003 29.3333H20.0003C26.667 29.3333 29.3337 26.6667 29.3337 20V12C29.3337 5.33332 26.667 2.66666 20.0003 2.66666H12.0003C5.33366 2.66666 2.66699 5.33332 2.66699 12V20C2.66699 26.6667 5.33366 29.3333 12.0003 29.3333Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  )
}
