import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/1");
    }
  }, [location, navigate]);
};
