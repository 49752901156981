import styles from './VideoPlay.module.scss'
import VideoPlayIcon from '../../assets/images/video-play.svg'

interface IVideoPlay {
  onClick: ()=> void
}

export const VideoPlay = ({onClick}: IVideoPlay) => {
  return (
    <button
      className={styles.videoPlay}
      type='button'
      onClick={onClick}
    >
          <img src={VideoPlayIcon} alt="Воспроизвести" />
          <span className={styles.btnText}>Посмотреть трейлер игры</span>
        </button>
  )
};


