import { CardProps } from "./Card.props";
import styles from "./Card.module.scss";
import { Game, MobileGame, PlayIcon } from "../../shared/ui";
import { useParams } from "react-router-dom";
import { gamesData } from "../../shared/constans/games_data";
import { AndroidWarningModal } from "../AndroidWarningModal";
import { useEffect, useState } from "react";
import { AndroidWarningBanner } from "../AndroidWarningBanner";

export const Card = ({
  onOpenMenu,
  onOpenPopup,
  className,
  ...props
}: CardProps): JSX.Element => {
  const { id } = useParams();
  const game = gamesData.find((game: any) => game.id === Number(id));
  const [isVisibleAndroindModal, setIsVisibleAndroindModal] = useState(false);
  const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  useEffect(() => {
    if (isIos) {
      setIsVisibleAndroindModal(true);
    } else {
      setIsVisibleAndroindModal(false);
    }
  }, []);
  return (
    <div className={styles.card} {...props}>
      <div className={styles.topWrapper} onClick={() => onOpenMenu()}>
        <div className={styles.wrapper}>
          <span className={styles.level}>
            Уровень <span>{game?.level}</span>
          </span>
          <span className={styles.elem}>{game?.nameLevel}</span>
        </div>
        <PlayIcon className={styles.playIcon} place="card" />
      </div>
      <h1 className={styles.title}>{game?.nameLevel}</h1>
      {isIos && <AndroidWarningBanner />}
      <div className={styles.game}>
        {game && (
          <Game
            loaderUrl={game.gamePaths.loaderUrl}
            dataUrl={game.gamePaths.dataUrl}
            frameworkUrl={game.gamePaths.frameworkUrl}
            codeUrl={game.gamePaths.codeUrl}
            onOpenPopup={onOpenPopup}
            id={game.id}
            streamingAssetsUrl={game.gamePaths.streamingAssetsUrl}
          />
        )}
      </div>
      {/* {/Android | iPhone/i.test(navigator.userAgent) ? (
        <div className={styles.game}>
          {game && <MobileGame gameLink={game?.gameLink} />}
        </div>
      ) : (
        <div className={styles.game}>
          {game && (
            <Game
              loaderUrl={game.gamePaths.loaderUrl}
              dataUrl={game.gamePaths.dataUrl}
              frameworkUrl={game.gamePaths.frameworkUrl}
              codeUrl={game.gamePaths.codeUrl}
              onOpenPopup={onOpenPopup}
              id={game.id}
              streamingAssetsUrl={game.gamePaths.streamingAssetsUrl}
            />
          )}
        </div>
      )} */}
      <div className={styles.descriptionLevel}>
        <h3 className={styles.subtitle}>
          Уровень <span>{game?.level}</span>
        </h3>
        <p className={styles.description}>{game?.descriptionLevel}</p>
        <div className={styles.container}>
          {game?.specification.map((item, index) => (
            <p key={index} className={styles.specification}>
              {item}
            </p>
          ))}
          {game?.addition && (
            <p className={styles.description}>{game?.addition}</p>
          )}
        </div>
      </div>
      {isVisibleAndroindModal && (
        <AndroidWarningModal
          isVisible={isVisibleAndroindModal}
          setIsVisible={setIsVisibleAndroindModal}
        />
      )}
    </div>
  );
};
