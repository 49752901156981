import styles from "./PopupVideo.module.scss";
import { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import { cn } from "../../helpers/classNames";
import ReactPlayer from "react-player/youtube";
import { CloseButton } from "../CloseButton/CloseButton";

export interface IPopupVideoProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOpen: boolean;
  onClose: () => void;
}

export const PopupVideo = ({ isOpen, onClose }: IPopupVideoProps) => {
  const [isPlaying, setIsPlaying] = useState(true);

  const handleClosePopup = () => {
    onClose();
  };
  return (
    <div
      className={cn(styles.popupVideo, {
        [styles.isOpen]: isOpen,
      })}
    >
      <div className={styles.content}>
        {isOpen && <span className={styles.text}>Загрузка...</span>}
        <div className={styles.IframeContainer}>
          {isOpen && (
            // <video className={styles.video} controls>
            //   <source src={videoSrc} type="video/mp4"></source>
            // </video>
            <iframe
              src="https://vk.com/video_ext.php?oid=-136505186&id=456239175&hd=2&autoplay=1"
              width="853"
              className={styles.iframe}
              height="480"
              allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          )}
        </div>
        {isOpen && (
          <CloseButton
            className={styles.closeButton}
            color="#FFFFFF"
            onClose={handleClosePopup}
          />
        )}
      </div>
    </div>
  );
};
