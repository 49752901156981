import { AppStoreLinkProps } from './AppStoreLink.props'
import styles from './AppStoreLink.module.scss'
import { cn } from '../../helpers/classNames'
import { Link } from 'react-router-dom'

export const AppStoreLink = ({
  store,
  href,
  className,
  ...props
}: AppStoreLinkProps): JSX.Element => {
  return (
    <Link
      to={href}
      {...props}
      className={cn(styles.link)}
    >
      <div
        className={cn(styles.imgContainer, {
          [styles.appStore]: store === 'appStore',
          [styles.ruStore]: store === 'ruStore',
          [styles.googlePlay]: store === 'googlePlay'
        })}
      />
    </Link>
  )
}
