import { HeaderProps } from './Header.props'
import styles from './Header.module.scss'
import { cn } from '../../shared/helpers/classNames/'
import { Logo } from '../../shared/ui'

export const Header = ({ className, ...props }: HeaderProps): JSX.Element => {
  return (
    <header
      className={cn(styles.header, undefined, [className || ''])}
      {...props}
    >
      <div className={styles.headerContent}>
        <Logo place="header" />
      </div>
    </header>
  )
}
