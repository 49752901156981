import { HomeProps } from './Home.props'
import styles from './Home.module.scss'
import { Outlet } from 'react-router-dom'
import { CloseButton, Logo } from '../../shared/ui'
import { Navigation } from '../../widgets'
import { cn } from '../../shared/helpers/classNames/'

export const Home = ({
  onCloseMenu,
  isOpen,
  children,
  className,
  ...props
}: HomeProps): JSX.Element => {
  return (
    <main className={styles.content} {...props}>
      <div
        className={cn(styles.nav, {
          [styles.isOpen]: isOpen
        })}
      >
        <div className={styles.container}>
          <div className={styles.navWrapper}>
            <Logo place="mobileNav" />
            <CloseButton onClose={onCloseMenu} color="#8FA7BF" />
          </div>
          <Navigation />
        </div>
      </div>
      <Outlet />
      <div className={styles.descriptionGame}>
        <h2 className={styles.heading}>Описание игры</h2>
        <p className={styles.description}>
          Вечер игры в историю оборачивается неожиданным приключением — во время
          спора Вера и Фома перепутали карточки настольной игры! Михаил
          Гаврилович попросил своего пса Алтая перенести их во времена
          исторических событий, чтобы ребята своими глазами увидели правдивый
          ход событий, стали их участниками и смогли всё вернуть на свои места.
        </p>
        <p className={styles.description}>
          Погрузитесь в удивительный мир русской истории! Станьте свидетелями и
          участниками приключений! Вам предстоит пройти исторический путь от
          борьбы за престол Киевской Руси до восхождения Михаила Романова на
          царский престол. Вашими верными помощниками будут Вера, Фома и пёс
          Алтай.
        </p>
      </div>
    </main>
  )
}
