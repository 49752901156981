import { Route, Routes, Navigate } from "react-router-dom";
import { Card, Footer, Header, Navigation } from "../widgets";
import { Consent, Home } from "../pages";
import "./App.scss";
import { useEffect, useState } from "react";
import { Popup } from "../widgets/Popup/Popup";
import { clearIndexedDB } from "../shared/helpers/clearIndexedDB";
import { useRedirect } from "../shared/hooks/useRedirect";
import { PopupVideo } from "../shared/ui/PopupVideo/PopupVideo";
import { VideoPlay } from "../shared/ui";

function App() {
  useRedirect();
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      clearIndexedDB();
    });
  }, []);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [popupVideoOpen, setPopupVideoOpen] = useState<boolean>(false);

  const handleOpenMenu = (): void => setMenuOpen(true);
  const handleCloseMenu = (): void => setMenuOpen(false);

  const handleOpenPopup = (): void => setPopupOpen(true);
  const handleClosePopup = (): void => setPopupOpen(false);

  const handleOpenPopupVideo = (): void => {
    console.log("first");
    setPopupVideoOpen(true);
  };
  const handleClosePopupVideo = (): void => setPopupVideoOpen(false);

  // useEffect(() => {
  //   if (/iPhone/i.test(navigator.userAgent)) {
  //     console.log('iPhone')
  //     setTimeout(() => {
  //       window.location.href =
  //         'https://apps.apple.com/us/app/вера-и-фома-игра-в-историю/id6503144010'
  //     }, 3000)
  //   } else if (/Android/i.test(navigator.userAgent)) {
  //     setTimeout(() => {
  //       window.location.href =
  //         'https://www.rustore.ru/catalog/app/ru.radiovera.vifii'
  //     }, 3000)
  //   }
  // }, [])

  return (
    <div className={menuOpen ? `${"page"} ${"noScroll"}` : "page"}>
      <Header className="header" />
      <div className="leftColumn">
        <VideoPlay onClick={handleOpenPopupVideo} />
        <div className="nav-container">
          <Navigation />
        </div>
      </div>
      <div className="rightColumn">
        <Routes>
          <Route
            path="/"
            element={<Home onCloseMenu={handleCloseMenu} isOpen={menuOpen} />}
          >
            <Route
              path=":id"
              element={
                <Card
                  onOpenMenu={handleOpenMenu}
                  onOpenPopup={handleOpenPopup}
                />
              }
            />
          </Route>
          <Route path="/consent" element={<Consent />} />
          <Route path="/" element={<Navigate to="/1" replace />} />
        </Routes>
      </div>
      <Footer className="footer" />
      <Popup isOpen={popupOpen} onClose={handleClosePopup} />
      <PopupVideo isOpen={popupVideoOpen} onClose={handleClosePopupVideo} />
    </div>
  );
}

export default App;
