import { Unity, useUnityContext } from "react-unity-webgl";
import styles from "./Game.module.css";
import { GamePropsI } from "./Game.props";
import { AppStoreLink } from "../AppStoreLink/AppStoreLink";
import FullScreenBtn from "./assets/arrow-square-right.png";
import { cn } from "../../helpers/classNames";

export const Game = ({
  onOpenPopup,
  loaderUrl,
  dataUrl,
  frameworkUrl,
  codeUrl,
  id,
  streamingAssetsUrl,
}: GamePropsI) => {
  const {
    unityProvider,
    requestFullscreen,
    loadingProgression,
    isLoaded,
    unload,
  } = useUnityContext({
    loaderUrl,
    dataUrl,
    frameworkUrl,
    codeUrl,
    cacheControl: () => "no-store",
    streamingAssetsUrl,
  });

  return (
    <div className={`${styles["unity-container"]}`}>
      <Unity
        unityProvider={unityProvider}
        className={styles.canvas}
        tabIndex={1}
        id={`level${id}`}
        key={id}
        devicePixelRatio={2}
        style={
          {
            // width: '960px'
            // height: '600px'
            // width: '100%',
            // height: '100%'
          }
        }
      />
      <div
        className={`${styles["unity-loading-bar"]}`}
        style={{ display: isLoaded ? "none" : "block" }}
      >
        <div className={`${styles["unity-logo"]}`}></div>
        <div className={`${styles["running-gif"]}`}></div>
        <div className={`${styles["unity-progress-bar-empty"]}`}>
          <div
            className={`${styles["unity-progress-bar-full"]}`}
            style={{ width: 100 * loadingProgression + "%" }}
          ></div>
        </div>
      </div>
      <div className={`${styles["unity-warning"]}`}> </div>
      <div className={`${styles["unity-footer"]}`}>
        <div className={styles.wraper}>
          <div className={styles.list}>
            <AppStoreLink
              href="https://www.rustore.ru/catalog/app/ru.radiovera.vifii"
              store="ruStore"
            />
            <AppStoreLink
              href="https://apps.apple.com/us/app/вера-и-фома-игра-в-историю/id6503144010"
              store="appStore"
            />
            <AppStoreLink
              href="https://play.google.com/store/apps/details?id=ru.radiovera.vifii"
              store="googlePlay"
            />
          </div>
          {isLoaded && (
            <div
              className={cn(`${styles["unity-fullscreen-button"]}`, {
                [styles.iosBtnStyles]: /iPhone|iPad|iPod/i.test(
                  navigator.userAgent
                ),
              })}
              onClick={() => requestFullscreen(true)}
            >
              <span>Развернуть на весь экран</span>
              <img src={FullScreenBtn} alt="full screen" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
