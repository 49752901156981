import { LogoProps } from './Logo.props'
import styles from './Logo.module.scss'
import { cn } from '../../helpers/classNames/'
import Logotype from '../../assets/images/logo.png'
export const Logo = ({ place, className }: LogoProps): JSX.Element => (
  <div
    className={cn(
      styles.logo,
      {
        [styles.placeHeader]: place === 'header',
        [styles.placeFooter]: place === 'footer',
        [styles.placeMobileNav]: place === 'mobileNav'
      },
      [className || '']
    )}
  >
    <img className={styles.img} src={Logotype} alt="Логотип Вера и Фома" />
  </div>
)
