import { PopupProps } from './Popup.props'
import styles from './Popup.module.scss'
import Img from '../../shared/assets/images/desktop-background2.png'
import { CloseButton, PlayIcon } from '../../shared/ui'
import { cn } from '../../shared/helpers/classNames'
import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react'
import { Link } from 'react-router-dom'

export const Popup = ({ isOpen, onClose }: PopupProps) => {
  const [email, setEmail] = useState('')
  const [emailDirty, setEmailDirty] = useState(false)
  const [checkboxIsTouched, setCheckboxIsTouched] = useState(false)
  const [emailError, setEmailError] = useState('Обязательное поле')
  const [formValid, setFormValid] = useState(false)
  const [successfully, setSuccessfully] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [answerSuccess, setAnswerSuccess] = useState(false)

  useEffect(() => {
    if (emailError || isSubscribed === false) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }, [emailError, isSubscribed])

  const handleChangeCheckbox: ChangeEventHandler<HTMLInputElement> = e => {
    setIsSubscribed(current => !current)
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setEmail(e.target.value)
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
    if (!regex.test(String(e.target.value).toLowerCase())) {
      setEmailError('Введите корректный Email')
    } else {
      setEmailError('')
    }
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = e => {
    switch (e.target.name) {
      case 'email':
        setEmailDirty(true)
        break
      case 'subscribe':
        setCheckboxIsTouched(true)
        break
    }
  }

  const handleClosePopup = useCallback(() => {
    onClose()
    setEmailDirty(false)
    setCheckboxIsTouched(false)
    setEmailError('Обязательное поле')
    setEmail('')
    setIsSubscribed(false)
    setFormValid(false)
    setSuccessfully(false)
  }, [onClose])

  useEffect(() => {
    const closeOnEscapePressed = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClosePopup()
      }
    }
    window.addEventListener('keyup', closeOnEscapePressed)
    return () => window.removeEventListener('keyup', closeOnEscapePressed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const url = 'https://veraifoma.ru/igra/api/subscribe'
  const data = { email: email }

  const handeSubmit = async () => {
    try {
      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
      let json = await response.json()
      setAnswerSuccess(json.success)
      setSuccessfully(true)
    } catch (error) {
      console.error('Ошибка:', error)
    }
  }

  return (
    <div
      className={cn(styles.popup, {
        [styles.isOpen]: isOpen
      })}
    >
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <img className={styles.image} src={Img} alt="" />
        </div>
        <div className={styles.wrapper}>
          {successfully ? (
            answerSuccess ? (
              <p className={styles.text}>
                Спасибо за заявку! Уведомление будет отправлено Вам на указанный
                почтовый адрес!
              </p>
            ) : (
              <p className={styles.text}>
                Данный почтовый адрес уже внесён в список для рассылки
                уведомлений
              </p>
            )
          ) : (
            <>
              <p className={styles.text}>
                Наша игра скоро выйдет, подпишитесь на уведомление и узнаете о
                выходе игры в числе первых!
              </p>
              <form
                className={styles.form}
                onSubmit={e => {
                  e.preventDefault()
                  handeSubmit()
                }}
              >
                <label className={styles.label} htmlFor="userEmail">
                  <input
                    className={styles.input}
                    type="email"
                    id="userEmail"
                    placeholder="Email"
                    onBlur={handleBlur}
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                  {emailDirty && emailError && (
                    <span className={styles.error}>{emailError || ''}</span>
                  )}
                </label>
                <button
                  className={styles.button}
                  type="submit"
                  disabled={!formValid}
                >
                  <span className={styles.btnText}>Подписаться</span>
                  <PlayIcon className={styles.playIcon} place="card" />
                </button>
                <label
                  className={styles.labelCheckbox}
                  htmlFor="privacy-policy"
                >
                  <input
                    className={styles.inputCheckbox}
                    type="checkbox"
                    id="privacy-policy"
                    name="subscribe"
                    onChange={handleChangeCheckbox}
                    onBlur={handleBlur}
                  />
                  <span
                    className={cn(styles.checkbox, {
                      [styles.erroCheckboxs]:
                        checkboxIsTouched && isSubscribed === false
                    })}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2644_1138)">
                        <path
                          d="M5 12L10 17L20 7"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2644_1138">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span className={styles.checkboxText}>
                    Даю согласие на {''}
                    <Link
                      to={'/consent'}
                      className={styles.link}
                      target="_blank"
                    >
                      обработку персональных данных
                    </Link>
                  </span>
                </label>
              </form>
            </>
          )}
        </div>
        <CloseButton
          className={styles.closeButton}
          color="#FFFFFF"
          onClose={handleClosePopup}
        />
      </div>
    </div>
  )
}
