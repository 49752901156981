export interface Game {
  id: number;
  level: number;
  nameLevel: string;
  descriptionLevel: string;
  specification: string[];
  addition?: string;
  gameLink: string;
  gamePaths: {
    loaderUrl: string;
    dataUrl: string;
    frameworkUrl: string;
    codeUrl: string;
    streamingAssetsUrl?: string;
  };
}

export const gamesData: Array<Game> = [
  {
    id: 1,
    level: 1,
    nameLevel: "Записка княгини Ольги",
    descriptionLevel:
      "Вечер игры в историю оборачивается неожиданным приключением — во время спора ребята перепутали карточки настольной игры! Но на помощь приходит чудесный пёс Алтай, который переносит героев внутрь игры, чтобы они сами восстановили историю и усвоили важный урок. Так Вера, Фома и Михаил Гаврилович оказываются в Киевской Руси в тот момент, когда князь Владимир выбирает религию. Если он ошибётся, ход истории изменится, а наши герои не вернутся обратно. Помоги им сделать всё правильно!",
    specification: [
      "Ваше приключение начинается вблизи Киева. Пройдя через локации древнего города, вам предстоит попасть в покои князя Владимира. Он стоит перед одним из важнейших моментов в своей жизни, способный изменить историю. Но как проникнуть в эти покои?",
    ],
    gameLink: "https://vera-i-foma.sector.show/MyGame/level1",
    gamePaths: {
      loaderUrl: "./MyGame/level1/Build/vif-webgl-1.loader.js",
      dataUrl: "./MyGame/level1/Build/vif-webgl-1.data",
      frameworkUrl: "./MyGame/level1/Build/vif-webgl-1.framework.js",
      codeUrl: "./MyGame/level1/Build/vif-webgl-1.wasm",
      streamingAssetsUrl: "./MyGame/level1/StreamingAssets",
    },
  },
  {
    id: 2,
    level: 2,
    nameLevel: "Время сделать выбор",
    descriptionLevel:
      "Ну и кутерьма, что ни шаг, то испытание! Узнав о том, что князь выбирает религию, Вера и Фома встречают православного миссионера* Синезуба и помогают ему распутать заговор.",
    specification: [
      "В ходе решения этой непростой задачи героям предстоит попасть попасть на княжеский пир и организовать встречу Михаила Гавриловича с князем. Но сначала нужна подходящая маскировка и острый нюх Алтая…",
      "* Миссионер Синезуб — бывший прославленный викинг и охранник византийского императора, который принял православие.",
    ],
    gameLink: "https://vera-i-foma.sector.show/MyGame/level2",
    gamePaths: {
      loaderUrl: "./MyGame/level2/Build/vif-webgl-2.loader.js",
      dataUrl: "./MyGame/level2/Build/vif-webgl-2.data",
      frameworkUrl: "./MyGame/level2/Build/vif-webgl-2.framework.js",
      codeUrl: "./MyGame/level2/Build/vif-webgl-2.wasm",
      streamingAssetsUrl: "./MyGame/level2/StreamingAssets",
    },
  },
  {
    id: 3,
    level: 3,
    nameLevel: "Послание Владимира Мономаха",
    descriptionLevel:
      "Принятие православия становится первым шагом в путешествии Веры, Фомы и их верного друга, пса Алтая. Но за ним героев ждет новое испытание — набеги половцев на Русь! Помогите русскому народу выстоять и сохранить свою культуру и веру.",
    specification: [
      "Новое путешествие начнется у стен Переяславля, где вам предстоит помочь дружине выйти в поход, ведь перепутались не только карточки, но и вся карта! А когда решающее сражение начнется, помогите Алтаю пронести стяг* Владимира Мономаха в самую гущу битвы и воодушевить воинов!",
      "Пройдя через эти испытания, Вера, Фома и Алтай смогут усвоить поучения из восстановленного послания Владимира Мономаха и стать свидетелями его восхождения на престол.",
      "* Стяг — воинское знамя.",
    ],
    gameLink: "https://vera-i-foma.sector.show/MyGame/level3",
    gamePaths: {
      loaderUrl: "./MyGame/level3/Build/vif-webgl-3.loader.js",
      dataUrl: "./MyGame/level3/Build/vif-webgl-3.data",
      frameworkUrl: "./MyGame/level3/Build/vif-webgl-3.framework.js",
      codeUrl: "./MyGame/level3/Build/vif-webgl-3.wasm",
      streamingAssetsUrl: "./MyGame/level3/StreamingAssets",
    },
  },
  {
    id: 4,
    level: 4,
    nameLevel: "Воцарение Владимира Мономаха",
    descriptionLevel:
      "Настали смутные времена. На улицах Киева опасно — волнения против князя Святополка в самом разгаре. Чтобы восстановить ход истории, вам предстоит помочь организовать вече* в Софийском соборе.",
    specification: [
      "Тем временем помощь нужна и самому Владимиру Мономаху — от того, попадет ли он на вече* и какой сделает выбор, зависит будущее Руси. Помогите новому правителю объединить разрозненные княжества и успокоить смуту.",
      "* Вече — народное собрание в Древней Руси для обсуждения общих дел.",
    ],
    gameLink: "https://vera-i-foma.sector.show/MyGame/level4",
    gamePaths: {
      loaderUrl: "./MyGame/level4/Build/vif-webgl-4.loader.js",
      dataUrl: "./MyGame/level4/Build/vif-webgl-4.data",
      frameworkUrl: "./MyGame/level4/Build/vif-webgl-4.framework.js",
      codeUrl: "./MyGame/level4/Build/vif-webgl-4.wasm",
      streamingAssetsUrl: "./MyGame/level4/StreamingAssets",
    },
  },
  {
    id: 5,
    level: 5,
    nameLevel: "Перед Невской битвой",
    descriptionLevel:
      "Вы помогли Вере и Фоме  восстановить историю, убедив князя взойти на престол. Готовы ли вы перейти к новому витку исторических испытаний?",
    specification: [
      "С Востока Русь осаждают монголы, с Запада ей угрожают шведы. Ваше приключение начнётся на берегу Невы в 1240 году, накануне событий, которые приведут к Невской битве в противостоянии шведскому войску захватчиков.",
    ],
    gameLink: "https://vera-i-foma.sector.show/MyGame/level5",
    gamePaths: {
      loaderUrl: "./MyGame/level5/Build/vif-webgl-5.loader.js",
      dataUrl: "./MyGame/level5/Build/vif-webgl-5.data",
      frameworkUrl: "./MyGame/level5/Build/vif-webgl-5.framework.js",
      codeUrl: "./MyGame/level5/Build/vif-webgl-5.wasm",
      streamingAssetsUrl: "./MyGame/level5/StreamingAssets",
    },
  },
  {
    id: 6,
    level: 6,
    nameLevel: "Дорога кочевников",
    descriptionLevel:
      "Монгольское иго подчинило себе Русь, а противники на Западе пытаются разобщить и захватить земли. Проявите смелость ради сохранения Отчизны в новом эпизоде приключений Веры и Фомы.",
    specification: [
      "Помогите князю Александру Невскому добраться до Золотой орды по приглашению хана Батыя. Если князь не достигнет ханства для судьбоносных переговоров, Русь так и не освободится от набегов захватчиков, а Вера и Фома не вернутся назад. Вперед, время не ждет!",
      "Кстати, а что здесь делают носороги?..",
    ],
    gameLink: "https://vera-i-foma.sector.show/MyGame/level6",
    gamePaths: {
      loaderUrl: "./MyGame/level6/Build/vif-webgl-6.loader.js",
      dataUrl: "./MyGame/level6/Build/vif-webgl-6.data",
      frameworkUrl: "./MyGame/level6/Build/vif-webgl-6.framework.js",
      codeUrl: "./MyGame/level6/Build/vif-webgl-6.wasm",
      streamingAssetsUrl: "./MyGame/level6/StreamingAssets",
    },
  },
  {
    id: 7,
    level: 7,
    nameLevel: "Надежда. Вера. Любовь",
    descriptionLevel:
      "Вам удалось защитить границы Древней Руси. Пора переноситься в следующую эпоху и увидеть своими глазами, как надежда, любовь и вера помогают спасти Отечество!",
    specification: [
      "Вере и Фоме предстоит стать свидетелями закладки Московского Кремля и венчания князя Дмитрия и Евдокии, дочери князя Суздальского. Этот союз положит конец многолетней братоубийственной войне за власть на Руси. Но не так просто заставить историю снова идти по назначенному пути. Наберитесь терпения — впереди таёжные земли и опустошенные улицы Москвы после пожарища 1365 года!",
    ],
    gamePaths: {
      loaderUrl: "./MyGame/level7/Build/vif-webgl-7.loader.js",
      dataUrl: "./MyGame/level7/Build/vif-webgl-7.data",
      frameworkUrl: "./MyGame/level7/Build/vif-webgl-7.framework.js",
      codeUrl: "./MyGame/level7/Build/vif-webgl-7.wasm",
      streamingAssetsUrl: "./MyGame/level7/StreamingAssets",
    },
    gameLink: "",
  },
  {
    id: 8,
    level: 8,
    nameLevel: "Борьба за независимость",
    descriptionLevel:
      "Белокаменная Москва возвышается над Русью — единой как никогда. Враждующие княжества заключили мир, а крепкие стены столицы теперь способны защитить от любых угроз. Впереди борьба за независимость от Золотой орды и Куликовская битва!",
    specification: [
      "После переговоров в Золотой орде и отказа Мамаю в выплате дани возвращение Дмитрия Донского в Московское княжество обещает быть напряжённым. Вам предстоит помочь ему собрать союзные войска, укрепить оборону и противостоять угрозе со стороны мамайских лазутчиков. Вместе с Верой и Фомой вы узнаете тонкости военного дела и столкнетесь с испытанием на отвагу. Алтай окажется незаменимым помощником в решении сложных задач на пути к победе. Будьте бдительны!",
    ],
    gamePaths: {
      loaderUrl: "./MyGame/level8/Build/vif-webgl-8.loader.js",
      dataUrl: "./MyGame/level8/Build/vif-webgl-8.data",
      frameworkUrl: "./MyGame/level8/Build/vif-webgl-8.framework.js",
      codeUrl: "./MyGame/level8/Build/vif-webgl-8.wasm",
      streamingAssetsUrl: "./MyGame/level8/StreamingAssets",
    },
    gameLink: "",
  },
  {
    id: 9,
    level: 9,
    nameLevel: "Смута",
    descriptionLevel:
      "Дни Смутных времен — сложное испытание для каждого патриота. Вы почти у цели, и скоро все нарушенные временные цепочки будут восстановлены. Но что это… осада?!",
    specification: [
      "Следуя за событиями Смутного времени вы окажетесь в Москве, которая попала под власть польских захватчиков. В критический момент, когда столица нуждается в спасении, вам нужно присоединиться к героям Минину и Пожарскому. Вера и Фома готовы рискнуть всем ради родного города. Вместе с вашим верным помощником Алтаем помогите героям освободить Москву и спасти заложников, среди которых сам будущий царь — Михаил Федорович Романов.",
    ],
    gamePaths: {
      loaderUrl: "./MyGame/level9/Build/vif-webgl-9.loader.js",
      dataUrl: "./MyGame/level9/Build/vif-webgl-9.data",
      frameworkUrl: "./MyGame/level9/Build/vif-webgl-9.framework.js",
      codeUrl: "./MyGame/level9/Build/vif-webgl-9.wasm",
      streamingAssetsUrl: "./MyGame/level9/StreamingAssets",
    },
    gameLink: "",
  },
  {
    id: 10,
    level: 10,
    nameLevel: "Во имя спасения",
    descriptionLevel:
      "Финальный рывок! Позади борьба за престол Киевской Руси, Невская битва и независимость от Золотой орды. Новый исторический эпизод поможет окончательно восстановить события, перепутанные во время игры, а Веру и Фому вернуть домой.",
    specification: [
      "Следующий этап — переезд Михаила Романова в Коломну после его избрания на царский престол. Польско-литовский отряд пытается ему помешать. Вера и Фома помогают организовать безопасный путь для будущего царя, преодолевая препятствия и угрозы. Ваша миссия — помочь гонцу, боярину Шереметьеву, доставить грамоту Михаилу Романову.",
      "Успех этой миссии поможет сосредоточить всю полноту власти в руках русского царя, который заложит основу новой династии и избавит страну от внутренних раздоров и внешних посягательств.",
    ],
    addition: "Отправятся ли Вера и Фома в новое путешествие?..",
    gamePaths: {
      loaderUrl: "./MyGame/level10/Build/vif-webgl-10.loader.js",
      dataUrl: "./MyGame/level10/Build/vif-webgl-10.data",
      frameworkUrl: "./MyGame/level10/Build/vif-webgl-10.framework.js",
      codeUrl: "./MyGame/level10/Build/vif-webgl-10.wasm",
      streamingAssetsUrl: "./MyGame/level10/StreamingAssets",
    },
    gameLink: "",
  },
];
