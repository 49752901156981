import { FooterProps } from './Footer.props'
import styles from './Footer.module.scss'
import { cn } from '../../shared/helpers/classNames/'
import { Logo } from '../../shared/ui'

export const Footer = ({ className, ...props }: FooterProps): JSX.Element => {
  return (
    <footer
      className={cn(styles.footer, undefined, [className || ''])}
      {...props}
    >
      <Logo place='footer' />
      <div className={styles.wrapper}>
        <small className={styles.copyright}>
          &copy;
          {` 2016-${new Date().getFullYear()} АНО Радиоканал «Вера, надежда, любовь»`}
        </small>
        <div className={styles.box}>
          <span>6+</span>
          <a
            className={styles.link}
            href='https://lp.radiovera.ru/publichnaya-oferta-o-zaklyuchenii-dogovora-pozhertvovaniya/'
            target='_blank'
            rel='noreferrer'
          >
            Политика конфиденциальности
          </a>
        </div>
      </div>
    </footer>
  )
}
