import { PlayIconProps } from './PlayIcon.props'
import styles from './PlayIcon.module.scss'
import { cn } from '../../helpers/classNames'

export const PlayIcon = ({
  place,
  isActive = false,
  className
}: PlayIconProps): JSX.Element => (
  <>
    {place === 'nav' && (
      <svg
        className={cn(
          styles.playIcon,
          {
            [styles.active]: isActive === true
          },
          [className || '']
        )}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.09961 12V10.52C9.09961 8.61 10.4496 7.84 12.0996 8.79L13.3796 9.53L14.6596 10.27C16.3096 11.22 16.3096 12.78 14.6596 13.73L13.3796 14.47L12.0996 15.21C10.4496 16.16 9.09961 15.38 9.09961 13.48V12Z"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {place === 'card' && (
      <svg
        className={cn('', undefined, [className || ''])}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.50033 18.3333H12.5003C16.667 18.3333 18.3337 16.6667 18.3337 12.5V7.5C18.3337 3.33333 16.667 1.66666 12.5003 1.66666H7.50033C3.33366 1.66666 1.66699 3.33333 1.66699 7.5V12.5C1.66699 16.6667 3.33366 18.3333 7.50033 18.3333Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.9502 12.9417L11.8835 10L8.9502 7.05833"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </>
)
