import ReactDOM from "react-dom";
import { useRef } from "react";

import styles from "./Modal.module.scss";
import { ModalPropsI } from "./Modal.props";

export const Modal = ({
  children,
  isVisible,
  close,
  additionalClasses = "",
  containerClassname,
}: ModalPropsI) => {
  const modalRef = useRef<HTMLDivElement>(null);

  return ReactDOM.createPortal(
    <div
      ref={modalRef}
      className={`${containerClassname ?? ""} ${styles.wrapper} ${
        isVisible ? styles.show : styles.hide
      }`}
      onClick={close}
    >
      <div
        className={`${styles.modal}  ${additionalClasses}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};
