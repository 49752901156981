import styles from './Consent.module.scss'
export const Consent = () => {
  return (
    <div className={styles.consent}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          Согласие на обработку персональных данных в рамках использования сайта
        </h1>
        <p className={styles.text}>
          Я, действуя своей волей и в своем интересе,{' '}
          <span className={styles.bold}>даю</span> АНО Радиоканал «Вера,
          надежда, любовь», ИНН 7718748268, ОГРН 1127799026134, адрес: 107553,
          г. Москва, улица Большая Черкизовская, дом 17, строение 2, (далее –
          оператор) <span className={styles.bold}>согласие на обработку</span>,
          включая сбор, запись, систематизацию, накопление, хранение,
          извлечение, уточнение (обновление, изменение), использование,
          обезличивание, блокирование, уничтожение, с использованием средств
          автоматизации и без использования средств автоматизации моих
          персональных данных, а именно: адрес электронной почты в{' '}
          <span className={styles.bold}>
            целях направления мне писем по адресу моей электронной почты с
            уведомлением о выходе игры «Вера и Фома. Игра в Историю».
          </span>
        </p>
        <p className={styles.text}>
          Настоящее согласие действует до «31» декабря 2024г. и является
          конкретным, предметным, информированным, сознательным и однозначным.
        </p>
        <p className={styles.text}>
          Персональные данные уничтожаются: по достижению целей обработки
          персональных данных; при ликвидации или реорганизации Оператора; на
          основании письменного обращения субъекта персональных данных с
          требованием о прекращении обработки его персональных данных путем
          направления Оператору письма по адресу электронной адрес:
          info@radiovera.ru
        </p>
        <p className={styles.text}>
          В соответствии с Федеральным законом «О персональных данных» Оператор
          не разглашает третьим лицам предоставленные персональные данные
          Пользователя без его письменного согласия, за исключением случаев
          требования данной информации государственными органами, имеющими
          соответствующие полномочия.
        </p>
        <p className={styles.text}>
          Персональные данные Пользователя обрабатываются Оператором в
          соответствии с Политикой в отношении обработки персональных данных.
        </p>
      </div>
    </div>
  )
}